<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.qrCodeToll')"
    id="qrCodeToll"
  >
    <div id="outer-title">{{ $t("i18n.qrCodeToll") }}</div>
    <jl-form :columns="columns" @onSubmit="onSubmit"></jl-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlForm from "../../components/form";
export default {
  name: "PhotoEditor",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "companyLogo",
          label: "comLogo",
          type: "upload",
          action: "logo",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "qrCodeLogo",
          label: "qrCodeLogo",
          type: "upload",
          action: "logo",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
        },
        {
          prop: "otherLogo",
          label: "otherLogo",
          type: "upload",
          action: "logo",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
        },
      ],
    });

    const onSubmit = async (row) => {
      await proxy.$api.system.saveCodeImage(row);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
    };

    return {
      ...toRefs(state),
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
#qrCodeToll {
  .form-button {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  #qrCodeToll {
    .form-button {
      text-align: center;
    }
  }
}
</style>
